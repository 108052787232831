import { FC } from 'react';
import { Node, mergeAttributes, ReactNodeViewRenderer, NodeViewProps } from '@tiptap/react';
import { setBlockType } from '@tiptap/pm/commands';
import { getNodeType } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    insertImageFigure: {
      insertImageFigureOld: (figureId?: string) => ReturnType;
      insertImageFigure: (attrs?: ImageFigureAttributes) => ReturnType;
    };
  }
}

export interface ImageFigureAttributes {
  id?: string | null;
  figureId: string | null;
  smallId: string | null;
  smallFilename: string | null;
  smallMetadata: string | null;
  largeId: string | null;
  largeFilename: string | null;
  largeMetadata: string | null;
  originalId: string | null;
  originalFilename: string | null;
  originalMetadata: string | null;
}

export const createImageFigureExtension = (viewComponent?: FC<NodeViewProps>) => {
  const addNodeView = viewComponent ? () => ReactNodeViewRenderer(viewComponent) : null;
  const node = Node.create({
    name: 'imageFigure',
    group: 'block',
    content: 'figcaption',
    inline: false,
    isolating: true,
    selectable: false,
    addAttributes() {
      return {
        figureId: {
          default: null,
        },
        smallId: {
          default: null,
        },
        smallFilename: {
          default: null,
        },
        smallMetadata: {
          default: null,
        },
        largeId: {
          default: null,
        },
        largeFilename: {
          default: null,
        },
        largeMetadata: {
          default: null,
        },
        originalId: {
          default: null,
        },
        originalFilename: {
          default: null,
        },
        originalMetadata: {
          default: null,
        },
      };
    },
    parseHTML() {
      return [
        {
          tag: 'react-component[data-type="imageFigure"]',
        },
      ];
    },

    renderHTML({ HTMLAttributes }) {
      return ['react-component', mergeAttributes(HTMLAttributes, { 'data-type': 'imageFigure' })];
    },
    addNodeView,
    addCommands() {
      return {
        insertImageFigure:
          (attrs?: ImageFigureAttributes) =>
          ({ commands, state }) => {
            const type = getNodeType('imageFigure', state.schema);
            const canSetBlock = setBlockType(type)(state);
            if (!canSetBlock) {
              return false;
            }
            return commands.insertContent({
              type: 'imageFigure',
              attrs: { ...attrs },
              content: [{ type: 'figcaption', content: [{ type: 'paragraph', content: [] }] }],
            });
          },
        insertImageFigureOld:
          (figureId?: string) =>
          ({ commands, state }) => {
            const type = getNodeType('imageFigure', state.schema);
            const canSetBlock = setBlockType(type)(state);
            if (!canSetBlock) {
              return false;
            }
            return commands.insertContent({
              type: 'imageFigure',
              attrs: { figureId },
              content: [{ type: 'figcaption', content: [{ type: 'paragraph', content: [] }] }],
            });
          },
      };
    },
  });

  return node;
};
